import React from "react"

const Wizard = ({ currentStep, children }) => {
  return (
    <div>
      {children.map((child, i) => {
        if (i === currentStep) {
          return <div key={i}>{child}</div>
        }
        return (
          <div className="hidden" key={i}>
            {child}
          </div>
        )
      })}
    </div>
  )
}

export default Wizard
