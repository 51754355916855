import React, { useState } from "react"
import { navigate } from "gatsby"
import axios from "axios"
import Button from "../common/Button"
import CheckboxCardControlled from "./elements/CheckboxCardControlled"
import CheckboxCardOnClick from "./elements/CheckboxCardOnClick"
import Input from "./elements/Input"
import Wizard from "./elements/Wizard"

const svg = {
  self: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className="inline"
      width={96}
      height={96}
      viewBox="0 0 24 24"
      strokeWidth={1}
      stroke="#000000"
      fill="none"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path stroke="none" d="M0 0h24v24H0z" fill="none" />
      <circle cx={12} cy={7} r={4} />
      <path d="M6 21v-2a4 4 0 0 1 4 -4h4a4 4 0 0 1 4 4v2" />
    </svg>
  ),
  directions: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className="inline"
      width={96}
      height={96}
      viewBox="0 0 24 24"
      strokeWidth={1}
      stroke="#000000"
      fill="none"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path stroke="none" d="M0 0h24v24H0z" fill="none" />
      <path d="M9 5h10l2 2l-2 2h-10a1 1 0 0 1 -1 -1v-2a1 1 0 0 1 1 -1" />
      <path d="M13 13h-7l-2 2l2 2h7a1 1 0 0 0 1 -1v-2a1 1 0 0 0 -1 -1" />
      <line x1={12} y1={22} x2={12} y2={17} />
      <line x1={12} y1={13} x2={12} y2={9} />
      <line x1={12} y1={5} x2={12} y2={3} />
    </svg>
  ),
  morgens: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className="inline"
      width="64"
      height="64"
      viewBox="0 0 24 24"
      strokeWidth="1"
      stroke="currentColor"
      fill="none"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path stroke="none" d="M0 0h24v24H0z" />
      <path d="M3 17h1m16 0h1M5.6 10.6l.7.7m12.1-.7l-.7.7M8 17a4 4 0 0 1 8 0" />
      <line x1="3" y1="21" x2="21" y2="21" />
      <path d="M12 9v-6l3 3m-6 0l3 -3" />
    </svg>
  ),
  mittags: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className="inline"
      width="64"
      height="64"
      viewBox="0 0 24 24"
      strokeWidth="1"
      stroke="currentColor"
      fill="none"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path stroke="none" d="M0 0h24v24H0z" />
      <circle cx="12" cy="12" r="4" />
      <path d="M3 12h1M12 3v1M20 12h1M12 20v1M5.6 5.6l.7 .7M18.4 5.6l-.7 .7M17.7 17.7l.7 .7M6.3 17.7l-.7 .7" />
    </svg>
  ),
  abends: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className="inline"
      width="64"
      height="64"
      viewBox="0 0 24 24"
      strokeWidth="1"
      stroke="currentColor"
      fill="none"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path stroke="none" d="M0 0h24v24H0z" />
      <path d="M3 17h1m16 0h1M5.6 10.6l.7.7m12.1-.7l-.7.7M8 17a4 4 0 0 1 8 0" />
      <line x1="3" y1="21" x2="21" y2="21" />
      <path d="M12 3v6l3 -3m-6 0l3 3" />
    </svg>
  ),
}

export default function Anfrage() {
  const [step, setStep] = useState(0)
  const [formData, setFormData] = useState({
    betreuung: "",
    website: "",
    keyword: "",
    firma: "",
    vorname: "",
    nachname: "",
    telefon: "",
    mail: "",
    erreichbarMorgens: false,
    erreichbarMittags: false,
    erreichbarAbends: false,
    kommentar: "",
  })

  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(false)

  const handleCheckbox = (e, value) => {
    e.preventDefault()
    setFormData({
      ...formData,
      betreuung: value,
    })
    setStep(prevCount => prevCount + 1)
  }

  const prevStep = e => {
    e.preventDefault()
    setStep(prevCount => prevCount - 1)
  }

  const nexStep = e => {
    e.preventDefault()
    setStep(prevCount => prevCount + 1)
  }

  const validateMaindata = e => {
    e.preventDefault()
    let inputs = document.getElementById("mainData").querySelectorAll("input")
    const errors = []
    inputs.forEach(input => {
      if (input.checkValidity() === false) {
        input.reportValidity()
        errors.push("Error")
      }
    })
    if (!errors.length > 0) {
      setStep(prevCount => prevCount + 1)
    }
  }

  const handleSubmit = async e => {
    e.preventDefault()
    setLoading(true)

    axios
      .post(
        `/.netlify/functions/send-enquiery-response`,
        JSON.stringify(formData)
      )
      .then(() => {
        navigate("/anfrage-gesendet/")
      })
      .catch(err => {
        setLoading(false)
        if (err?.response?.data && typeof err.response.data === "string") {
          setError(err.response.data)
        } else {
          setError(
            "Etwas ist schief gelaufen. Bitte melde Dich direkt bei uns per E-Mail: kontakt@seo-premium-agentur.de"
          )
        }
      })
  }

  return (
    <form
      name="AnfrageReply"
      method="POST"
      onSubmit={e => handleSubmit(e)}
      id="enquieryform"
    >
      <Wizard currentStep={step}>
        <div>
          <div className="mt-8 mb-4">
            <h3 className="text-lg font-bold leading-6 text-gray-900">
              Wer betreut Deine Website?
            </h3>
          </div>

          <div className="grid grid-cols-1 gap-4 mb-4 sm:grid-cols-2">
            <CheckboxCardOnClick
              label="Ich betreue meine Webseite selbst"
              svg={svg.self}
              onClick={e => handleCheckbox(e, "Selbst")}
            />
            <CheckboxCardOnClick
              label="Ich brauche Hilfe für meine Webseite"
              svg={svg.directions}
              onClick={e => handleCheckbox(e, "Sucht Dienstleister")}
            />
          </div>
        </div>

        <div id="mainData">
          <div className="mt-8 mb-4">
            <h3 className="text-lg font-bold leading-6 text-gray-900">
              Kontaktdaten
            </h3>
            <p className="max-w-2xl mt-1 text-sm leading-5 text-gray-500">
              Diese Informationen sind wichtig, damit ich Dich kontaktieren
              kann.
            </p>
          </div>
          <div className="mb-4">
            <Input
              type="text"
              label="Website (falls vorhanden)"
              id="website"
              name="website"
              placeholder="Deine aktuelle Website"
              autoComplete="url"
              value={formData.website}
              onChange={e =>
                setFormData({
                  ...formData,
                  website: e.target.value,
                })
              }
            />
          </div>
          <div className="mb-4">
            <Input
              type="text"
              label="Wunschkeyword (falls vorhanden)"
              id="keyword"
              name="keyword"
              placeholder="Dein Wunschkeyword"
              value={formData.keyword}
              onChange={e =>
                setFormData({
                  ...formData,
                  keyword: e.target.value,
                })
              }
            />
          </div>
          <div className="mb-4">
            <Input
              type="text"
              label="Firma"
              id="firma"
              name="firma"
              placeholder="Deine Unternehmensname"
              autoComplete="organization"
              value={formData.firma}
              onChange={e =>
                setFormData({
                  ...formData,
                  firma: e.target.value,
                })
              }
            />
          </div>
          <div className="grid-cols-2 gap-4 sm:grid ">
            <div className="mb-4">
              <Input
                type="text"
                label="Vorname"
                id="vorname"
                name="vorname"
                placeholder="Dein Vorname"
                autoComplete="given-name"
                value={formData.vorname}
                onChange={e =>
                  setFormData({
                    ...formData,
                    vorname: e.target.value,
                  })
                }
                required
              />
            </div>
            <div className="mb-4">
              <Input
                type="text"
                label="Nachname"
                id="nachname"
                name="nachname"
                placeholder="Dein Nachname"
                autoComplete="family-name"
                value={formData.nachname}
                onChange={e =>
                  setFormData({
                    ...formData,
                    nachname: e.target.value,
                  })
                }
                required
              />
            </div>
          </div>
          <div className="mb-4">
            <Input
              type="tel"
              label="Telefon"
              id="telefon"
              name="telefon"
              placeholder="Deine Telefonnummer"
              autoComplete="tel"
              inputMode="numeric"
              value={formData.telefon}
              onChange={e =>
                setFormData({
                  ...formData,
                  telefon: e.target.value,
                })
              }
              required
            />
          </div>
          <div className="mb-4">
            <Input
              type="email"
              label="E-Mail"
              id="email"
              name="mail"
              placeholder="Deine E-Mailadresse"
              autoComplete="email"
              value={formData.mail}
              onChange={e =>
                setFormData({
                  ...formData,
                  mail: e.target.value,
                })
              }
              required
            />
          </div>

          <div className="grid grid-cols-2 gap-3">
            <Button
              onClick={e => prevStep(e)}
              text="Zurück"
              className="w-full"
              white
            />
            <Button
              onClick={e => validateMaindata(e)}
              text="Weiter"
              className="w-full"
            />
          </div>
        </div>

        <div>
          <div className="mt-8 mb-4">
            <h3 className="text-lg font-bold leading-6 text-gray-900">
              Wann bist Du erreichbar?
            </h3>
            <p className="max-w-2xl mt-1 text-sm leading-5 text-gray-500">
              Diese Informationen sind wichtig, damit ich Dich nicht zu
              unpassenden Zeiten kontaktiere.
            </p>
          </div>

          <div className="grid grid-cols-1 gap-4 mb-4 sm:grid-cols-3">
            <CheckboxCardControlled
              label="Morgens"
              value={formData.erreichbarMorgens}
              onChange={() =>
                setFormData(prevState => {
                  return {
                    ...formData,
                    erreichbarMorgens: !prevState.erreichbarMorgens,
                  }
                })
              }
              svg={svg.morgens}
            />

            <CheckboxCardControlled
              label="Mittags"
              name="Erreichbar-Mittags"
              id="erreichbarkeit-mittags"
              value={formData.erreichbarMittags}
              onChange={() =>
                setFormData(prevState => {
                  return {
                    ...formData,
                    erreichbarMittags: !prevState.erreichbarMittags,
                  }
                })
              }
              svg={svg.mittags}
            />

            <CheckboxCardControlled
              label="Abends"
              name="Erreichbar-Abends"
              id="erreichbarkeit-abends"
              value={formData.erreichbarAbends}
              onChange={() =>
                setFormData(prevState => {
                  return {
                    ...formData,
                    erreichbarAbends: !prevState.erreichbarAbends,
                  }
                })
              }
              svg={svg.abends}
            />
          </div>
          <div className="grid grid-cols-2 gap-3">
            <Button
              onClick={e => prevStep(e)}
              text="Zurück"
              className="w-full"
              white
            />
            <Button
              onClick={e => nexStep(e)}
              text="Weiter"
              className="w-full"
            />
          </div>
        </div>

        <div>
          <div className="mt-8 mb-1">
            <h3 className="text-lg font-bold leading-6 text-gray-900">
              Hast Du was auf dem Herzen?
            </h3>
            <p className="max-w-2xl mt-1 text-sm leading-5 text-gray-500">
              Teile mir mit falls Du noch was mitteilen möchtest.
            </p>
          </div>

          <div className="mb-4">
            <label
              className="block mb-2 text-sm font-medium text-gray-900"
              htmlFor="kommentar"
            >
              Kommentar
            </label>
            <textarea
              cols="50"
              rows="4"
              className="w-full p-4 leading-tight text-gray-700 border rounded shadow appearance-none focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-brand-yellow"
              id="kommentar"
              name="kommentar"
              value={formData.kommentar}
              onChange={e =>
                setFormData({
                  ...formData,
                  kommentar: e.target.value,
                })
              }
            />
          </div>

          <div>
            <p className="mt-3 mb-6 text-sm leading-5">
              Ich respektiere Dein Vertrauen und behandeln Deine Daten mit
              Respekt. Mit dem Absenden dieses Formulars akzeptierst Du meine{" "}
              <a
                href="/datenschutz/"
                className="font-medium underline"
                target="_blank"
              >
                Datenschutzerklärung
              </a>
              .
            </p>
          </div>
          {error ? <div className="bg-yellow-300 p-3 my-3">{error}</div> : null}
          <div className="grid grid-cols-2 gap-3">
            <Button
              onClick={e => prevStep(e)}
              text="Zurück"
              className="w-full"
              white
            />
            {loading ? (
              <Button
                onClick={() => null}
                text={"lädt..."}
                className="w-full cursor-wait"
              />
            ) : (
              <Button submit text={"Anfrage senden"} className="w-full" />
            )}
          </div>
        </div>
      </Wizard>
    </form>
  )
}
