import React from "react"
import Anfrage from "../components/form/Anfrage"
import Layout from "../components/layouts/Default"
import RequestLeftCol from "./../components/layouts/partials/RequestLeftCol"
import RequestRightCol from "./../components/layouts/partials/RequestRightCol"

export default function Page({ data, location }) {
  return (
    <Layout
      location={location}
      title="Anfragen SEO Agentur Stuttgart"
      desc=""
      noHeader
      grayFooter
    >
      <section>
        <div className="flex flex-wrap">
          <div className="w-full max-w-screen-lg p-8 lg:w-8/12 lg:order-2 lg:p-16">
            <RequestRightCol>
              <h1 className="text-2xl font-bold md:text-3xl lg:text-4xl">
                Kostenlosen SEO-CHECK für Deine Google Seite 1 Platzierung anfragen
              </h1>
              <Anfrage />
              {/* <form
                name="Anfrage"
                method="POST"
                data-netlify="true"
                action="/anfrage-gesendet"
              >
                <input type="hidden" name="form-name" value="Anfrage" />
                <input type="hidden" name="Anfrage" value="Website Anfrage" />

                <Wizard>
                  <div>
                    <div className="mt-8 mb-4">
                      <h3 className="text-lg font-bold leading-6 text-gray-900">
                        Wer betreut Deine Website?
                      </h3>
                      <p className="max-w-2xl mt-1 text-sm leading-5 text-gray-500">
                        Diese Informationen sind wichtig, damit ich Dich nicht
                        zu unpassenden Zeiten kontaktiere.
                      </p>
                    </div>

                    <div className="grid grid-cols-1 gap-4 mb-4 sm:grid-cols-2">
                      <CheckboxCard
                        label="Morgens"
                        name="Erreichbar-Morgens"
                        value="Ich betreue meine Website selbst"
                        id="erreichbarkeit-morgens"
                        svg="asd"
                      />

                      <CheckboxCard
                        label="Mittags"
                        name="Erreichbar-Mittags"
                        value="Ich betreue meine Website selbst"
                        id="erreichbarkeit-mittags"
                        svg="asd"
                      />
                    </div>
                  </div>

                  <div>
                    <h3 className="text-lg font-bold leading-6 text-gray-900">
                      Kontaktdaten
                    </h3>
                    <p className="max-w-2xl mt-1 text-sm leading-5 text-gray-500">
                      Diese Informationen sind wichtig, damit ich Dich
                      kontaktieren kann.
                    </p>
                    <Input />
                  </div>

                  <FormMainData />
                </Wizard>
              </form> */}
            </RequestRightCol>
          </div>
          <div className="w-full p-8 bg-gray-100 lg:w-4/12 lg:order-1 lg:p-16 ">
            <RequestLeftCol />
          </div>
        </div>
      </section>
    </Layout>
  )
}
