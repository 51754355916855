import React from "react"

export default function CheckboxCard({ onClick, svg, label }) {
  return (
    <button
      className="flex self-stretch w-full bg-white"
      onClick={onClick}
      onKeyDown={onClick}
    >
      <div
        className={`w-full shadow rounded text-center p-5 transition-colors duration-300 self-stretch cursor-pointer border border-transparent relative`}
      >
        <div>
          <div className="text-center">{svg}</div>
          <div>{label}</div>
        </div>
      </div>
    </button>
  )
}
